//10.5.1.30 - Test
//10.5.1.30 - Preprod

const URL_TEST = false; //false para desarrollo, true para produccion
const KEYS = false; //false para desarrollo, true para produccion

export const dimo_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8084/dimoservices/rest' : '/ws-dimo/dimoservices/rest';

export const account_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-dimo/dimo/catastro' : '/ws-dimo/dimo/catastro';

export const operations_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-operaciones/dimo/operacion' : '/ws-operaciones/dimo/operacion';

export const auth_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-user/user/login' : '/ws-user/user/login';

export const creditcard_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8083/ws-tarjetahabiente/tarjetaCredito' : '/ws-tarjetahabiente/tarjetaCredito';

export const prepaidcard_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8083/ws-tarjetahabiente/tarjetaPrepaga' : '/ws-tarjetahabiente/tarjetaPrepaga';

export const extract_creditcard_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8083/ws-tarjetahabiente/tarjetaCredito' : '/ws-tarjetahabiente/tarjetaCredito';

export const extract_descargar_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8084/facturacionelectronica/rest' : '/facturacionelectronica/rest';

export const ceibo_prm_sp_services_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8083/ws-tarjetahabiente/prmservices/v2/sprunner' : '/ws-tarjetahabiente/prmservices/v2/sprunner';

export const participants_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-ceibo/dimo/ceibo' : '/ws-ceibo/dimo/ceibo';

export const transaction_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-operaciones/dimo/operacion' : '/ws-operaciones/dimo/operacion';

export const payment_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8084/PaymentGateway/rest' : '/ws-payment-engine/PaymentGateway/rest';

export const coop_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8081/ws-cooperativa/coop/cooperativa' : '/ws-cooperativa/coop/cooperativa';

export const bank_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8084/switchbancario/rest' : '/switchbancario/rest';

export const kyc_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8087/kyc' : '/ws-user-kyc';

export const operaciones_dimo_service_api_url = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://10.5.1.30:8084/operacionesdimo/rest' : '/operacionesdimo/rest';

export const url_tp_dimo = false ? 'https://estatico.cabal.coop.py/public/dimo/afinidades' : 'https://estaticopre.cabal.coop.py/public/dimo/afinidades';

//se agrego para validar el periodo de facturacion, de acuerdo a la fecha, si es true es preprod, si es false es produccion
export const periodoFacturacionKude = false ? '202206' : '202306';
